import React from "react"
import styled, { css } from "styled-components"

const MainDescription = styled.div`
  text-align: center;
  @media (max-width: 900px) {
    margin-top: 50px;
  }
`

const StyledText = styled.div`
  cursor: default;
  color: white;
  text-shadow: 2px 2px 2px black;
  font-size: 4vw;
  letter-spacing: 2px;
  font-weight: 600;
  margin: 5px;

  @media (max-width: 1200px) {
    margin: 0;
    font-size: 5vw;
  }
  @media (max-width: 500px) {
    margin: 0;
    font-size: 7vw;
  }

  ${props =>
    props.secondary &&
    css`
      font-size: 5vw;
      text-transform: uppercase;
      margin-bottom: 20px;

      @media (max-width: 1200px) {
        font-size: 6vw;
      }
      @media (max-width: 500px) {
        margin: 0;
        font-size: 8vw;
      }
    `};
`

const GreenLetter = styled.span`
  color: ${({ theme }) => theme.green};
  font-size: 5vw;
  text-shadow: 2px 2px 2px black;
  @media (max-width: 1200px) {
    font-size: 6vw;
  }
  @media (max-width: 500px) {
    margin: 0;
    font-size: 8vw;
  }
`

const MainContent = () => {
  return (
    <MainDescription>
      <StyledText secondary>Firma z drewna</StyledText>
      <StyledText>
        <GreenLetter>J</GreenLetter>edna deska
      </StyledText>
      <StyledText>
        <GreenLetter>T</GreenLetter>ysiące możliwości
      </StyledText>
    </MainDescription>
  )
}

export default MainContent
