import React, { useEffect } from "react"
import styled from "styled-components"
import Logo from "../components/atoms/Logo"
import MainContent from "../components/atoms/MainContent"
import Products from "../components/organisms/Products"
import logo from "../assets/logo/logo.svg"
import MainTemplate from "../templates/MainTemplate"
import { ResizeGridItems } from "../helpers/functions"
import "bootstrap/dist/css/bootstrap.min.css"
import "../index.css"
import BackgroundSection from "../components/atoms/BackgroundSection"

const MainSection = styled.section`
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  align-items: center;
  padding: 60px 10% 0 10%;
  @media (max-width: 1200px) {
    flex-direction: column;
    justify-content: center;
  }
`

const ProductsSection = styled.section`
  min-height: 100vh;
  padding: 30px 20px 70px 20px;
  background-color: ${({ theme }) => theme.primary};
`

const Home = () => {
  useEffect(() => {
    ResizeGridItems()
    window.onresize = ResizeGridItems
  })

  return (
    <MainTemplate>
      <BackgroundSection />
      <MainSection id="home">
        <Logo src={logo} alt="Firma z drewna" />
        <MainContent />
      </MainSection>
      <ProductsSection id="products">
        <Products />
      </ProductsSection>
      <div style={{ height: "15vh" }} />
    </MainTemplate>
  )
}
export default Home
