import React, { useEffect, useState } from "react"
import styled, { keyframes } from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import ProductIcon from "../atoms/ProductIcon"
import ProductDescription from "../atoms/ProductDescription"
import imagesData from "../../products/imagesData.json"
import { ResizeGridItems } from "../../helpers/functions"
import { ArrowIosDownward } from "@styled-icons/evaicons-solid/ArrowIosDownward"

const arrowDownAnimation = keyframes`
0%, 100%{
  transform: translateY(0);
}
50%{
  transform: translateY(3px);
}
`

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`

const ProductsWrapper = styled.div`
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 450px));
  grid-auto-rows: 0;
  grid-gap: 30px;
  @media (max-width: 400px) {
    grid-template-columns: repeat(auto-fill, minmax(0, 450px));
  }
`

const Header = styled.div`
  color: black;
  cursor: default;
  letter-spacing: 2px;
  font-size: 5em;
  margin-top: 30px;
  margin-bottom: 30px;
  font-family: "Poiret One";
  font-weight: 600;
  text-align: center;
  @media (max-width: 400px) {
    font-size: 15vw;
  }
`

const EmptyView = styled.h2`
  grid-column: 1/-1;
  margin: auto;
  color: black;
  cursor: default;
  font-size: 50px;
  font-weight: 600;
`

const ButtonsWrapper = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin: 30px 0;
`
const ShowMoreLessButton = styled.div`
  cursor: pointer;
  background-color: ${({ theme }) => theme.green};
  border-radius: 5px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  width: 200px;
  padding: 10px 10px 10px 35px;
  color: #fff;
  margin: 0 5px;
  :hover {
    > svg {
      animation: ${arrowDownAnimation} 0.8s ease-in infinite;
    }
  }
  > svg {
    transition: 0.5s;
    width: 25px;
    height: 25px;
    color: #fff;
    margin: 0 5px 5px 5px;
  }
`

const Products = () => {
  const initialProductsToShowCount = 10
  const [productsToShow, setProductsToShow] = useState(
    initialProductsToShowCount
  )
  useEffect(() => {
    ResizeGridItems()
  }, [productsToShow])
  const products = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          extension: { in: ["png", "jpg", "jpeg"] }
          sourceInstanceName: { eq: "products" }
        }
      ) {
        edges {
          node {
            sourceInstanceName
            relativePath
            publicURL
            name
            absolutePath
            childImageSharp {
              small: fixed(width: 450, quality: 100) {
                ...GatsbyImageSharpFixed
              }
              large: fixed(height: 700, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `).allFile.edges

  return products && products.length > 0 ? (
    <>
      <Header>Produkty</Header>
      <ProductsWrapper className="products">
        {imagesData
          .map(product => {
            const title = product.title ?? ""
            const description = product.description ?? ""
            const imageName = product.image ?? ""
            const image = products?.find(
              product => product.node.relativePath === imageName
            )
            const photo = image?.node?.childImageSharp
            return { photo, title, description }
          })
          .filter(({ photo }) => photo)
          .slice(0, productsToShow)
          .map(({ photo, title, description }, index) => {
            return (
              <div
                key={index}
                style={{ gridRowEnd: "span 250" }} // Average height of a grid-item to mitigate resizing consquences
                className="grid-item"
              >
                <StyledWrapper className="content">
                  <ProductIcon photo={photo} alt={title} />
                  <ProductDescription title={title} description={description} />
                </StyledWrapper>
              </div>
            )
          })}
      </ProductsWrapper>
      <ButtonsWrapper>
        {/* {productsToShow > initialProductsToShowCount ? (
          <ShowMoreLessButton
            onClick={() => {
              setProductsToShow(x =>
                Math.max(
                  initialProductsToShowCount,
                  x - initialProductsToShowCount
                )
              )
            }}
          >
            <ArrowIosUpwardOutline />
            Pokaż mniej
          </ShowMoreLessButton>
        ) : (
          <></>
        )} */}
        {productsToShow < products.length ? (
          <ShowMoreLessButton
            onClick={() => {
              setProductsToShow(x =>
                Math.min(products.length, x + initialProductsToShowCount)
              )
            }}
          >
            Pokaż więcej
            <ArrowIosDownward />
          </ShowMoreLessButton>
        ) : (
          <></>
        )}
      </ButtonsWrapper>
    </>
  ) : (
    <EmptyView>Brak produktów</EmptyView>
  )
}

export default Products
