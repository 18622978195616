import React, { useEffect } from "react"
import styled from "styled-components"
import MenuElement from "../atoms/MenuElement"
import { ExecuteWhen } from "../../helpers/functions"

const StyledWrapper = styled.ul`
  position: relative;
  display: flex;
  margin-bottom: 0;

  @media (max-width: 800px) {
    position: absolute;
    margin-left: 0;
    top: -100%;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);
    padding: 50px 0px;
    text-align: center;
    overflow: auto;
    transition: 0.5s;
    background: ${({ theme }) => theme.primary};
    flex-direction: column;
    transform: translateY(-100%);

    &.active {
      transform: translateY(0);
      top: 60px;
      a {
        transition: opacity 0.5s;
        transition-delay: 0.3s;
        opacity: 1;
      }
    }
  }
`

const Menu = ({ activeMenu, callback }) => {
  const MenuOnScroll = () => {
    var html = document.getElementsByTagName("html")[0]
    var scrollDistance = html.scrollTop + window.innerHeight / 2
    var sections = document.getElementsByTagName("section")
    var navElements = document.getElementsByClassName("navigation")
    Array.from(sections)
      .filter(
        section =>
          section.offsetTop <= scrollDistance ||
          section.offsetTop + section.offsetHeight <=
            html.scrollTop + window.innerHeight
      )
      .slice(-1)
      .forEach(section => {
        Array.from(navElements).forEach(e => {
          Array.from(e.childNodes)
            .filter(node => node.tagName === "A")
            .forEach(node => {
              node.classList.remove("active")
              if (node.hash === `#${section.id}`) {
                node.classList.add("active")
                if (window.location.hash !== node.hash)
                  window.history.replaceState(undefined, undefined, node.hash)
              }
            })
        })
      })
  }

  let delayedOnScrollTimeout = null
  const onscroll = () => {
    if (delayedOnScrollTimeout) clearTimeout(delayedOnScrollTimeout)
    if (document.readyState !== "complete") return
    delayedOnScrollTimeout = setTimeout(MenuOnScroll, 250)
  }

  useEffect(() => {
    window.onscroll = onscroll
    ExecuteWhen(onscroll, () => document.readyState === "complete")
  })

  return (
    <StyledWrapper className={activeMenu ? "active" : ""}>
      <MenuElement scrollTo="home" title="Strona główna" callback={callback} />
      <MenuElement scrollTo="products" title="Produkty" callback={callback} />
      <MenuElement scrollTo="contact" title="Kontakt" callback={callback} />
    </StyledWrapper>
  )
}

export default Menu
