import styled from "styled-components"

const Logo = styled.img`
  width: 35vw;
  margin-bottom: 0;
  max-width: 500px;
  cursor: default;

  @media (max-width: 1200px) {
    width: 80vw;
  }
`

export default Logo
