import React from "react"
import styled, { css } from "styled-components"
import { Phone } from "@styled-icons/feather/Phone"
import { Mail } from "@styled-icons/feather/Mail"
import { Home } from "@styled-icons/feather/Home"
import { FacebookMessenger } from "@styled-icons/fa-brands/FacebookMessenger"

const CompanyContaner = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    margin: 0 auto;
  }

  @media (max-width: 470px) {
    margin-left: 0;
  }
`

const StyledItemWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`

const StyledText = styled.div`
  font-size: 1.5em;
  cursor: default;
  color: black;
  word-break: break-word;

  @media (max-width: 400px) {
    font-size: 6vw;
  }

  :hover {
    font-weight: 600;
  }

  ${props =>
    props.messenger &&
    css`
      color: #0e8ef1;
      cursor: pointer;
    `};
`

const StyledMessengerLink = styled.a`
  display: flex;
  align-items: center;
  margin: 10px 0;
width: fit-content;
  :hover {
    text-decoration: none;
  }
`

const StyledMessenger = styled(FacebookMessenger)`
  margin-right: 5px;
  cursor: pointer;
  color: #0e8ef1;
  @media (max-width: 400px) {
  }
`

const IconStyle = {
  marginRight: "5px",
  color: "black",
}

const CompanyInfo = () => {
  return (
    <CompanyContaner>
      <StyledText style={{ fontWeight: "600" }}>
        Skontaktuj się z nami:
      </StyledText>
      <StyledItemWrapper>
        <Home style={IconStyle} size="30" />
        <StyledText>Powstańców Wielkopolskich 3/8, 86-200 Chełmno</StyledText>
      </StyledItemWrapper>
      <StyledItemWrapper>
        <Phone style={IconStyle} size="30" />
        <StyledText>722 270 317</StyledText>
      </StyledItemWrapper>
      <StyledItemWrapper>
        <Mail style={IconStyle} size="30" />
        <StyledText>firmazdrewna@gmail.com</StyledText>
      </StyledItemWrapper>
      <StyledMessengerLink
        href="https://m.me/117128053531341"
        rel="noreferrer"
        target="_blank"
      >
        <StyledMessenger size="30" />
        <StyledText messenger>Napisz do nas na messengerze</StyledText>
      </StyledMessengerLink>
    </CompanyContaner>
  )
}

export default CompanyInfo
