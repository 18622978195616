import React, { Component } from "react"
import styled from "styled-components"
import CompanyInfo from "../atoms/CompanyInfo"
import MediaIcons from "../atoms/MediaIcons"
import Map from "../atoms/Map"

const StyledContactWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 40px;
  @media (max-width: 1000px) {
    flex-direction: column;
    padding: 20px 20px;
  }
`

class Contact extends Component {
  render() {
    return (
      <StyledContactWrapper>
        <CompanyInfo />
        <Map />
        <MediaIcons />
      </StyledContactWrapper>
    )
  }
}

export default Contact
