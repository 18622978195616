import React from "react"
import styled from "styled-components"

const StyledToggle = styled.div`
  display: none;

  @media (max-width: 800px) {
    display: block;
    position: relative;
    width: 30px;
    height: 30px;
    cursor: pointer;

    :before {
      content: "";
      position: absolute;
      top: 4px;
      width: 100%;
      height: 2px;
      background: black;
      z-index: 1;
      box-shadow: 0 10px 0 black;
      transition: 0.5s;
    }

    :after {
      content: "";
      position: absolute;
      bottom: 4px;
      width: 100%;
      height: 2px;
      background: black;
      z-index: 1;
      transition: 0.5s;
    }
  }
  @media (max-width: 300px) {
    width: 15px;
    height: 15px;
  }
`

const Toggle = ({ callback }) => {
  return <StyledToggle onClick={callback} />
}

export default Toggle
