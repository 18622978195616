import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"

const StyledBackgroundImage = styled(BackgroundImage)`
  width: 100%;
  height: 100vh;
  position: fixed !important;
  background-size: cover;
  background-position: 50% 0;
  z-index: -100;
  transform: ScaleX(-1);
`

const BackgroundSection = () => {
  const background = useStaticQuery(graphql`
    query {
      image_1920_width: file(relativePath: { eq: "background.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image_1500_width: file(relativePath: { eq: "background.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image_1080_width: file(relativePath: { eq: "background.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1080, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image_768_width: file(relativePath: { eq: "background.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 768, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image_360_width: file(relativePath: { eq: "background.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 360, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const sources = [
    background.image_1920_width.childImageSharp.fluid,
    {
      ...background.image_1500_width.childImageSharp.fluid,
      media: `(max-width: 1500px)`,
    },
    {
      ...background.image_1080_width.childImageSharp.fluid,
      media: `(max-width: 1080px)`,
    },
    {
      ...background.image_768_width.childImageSharp.fluid,
      media: `(max-width: 768px)`,
    },
    {
      ...background.image_360_width.childImageSharp.fluid,
      media: `(max-width: 360px)`,
    },
  ]

  return <StyledBackgroundImage fluid={sources} />
}

export default BackgroundSection
