import React, { useState } from "react"
import styled from "styled-components"
import Menu from "../molecules/Menu"
import logoText from "../../assets/logo/logo-one-line-text.svg"
import Toggle from "../atoms/Toggle"
import LogoText from "../atoms/LogoText"

const HeaderContainer = styled.header`
  position: fixed;
  width: 100%;
  height: 60px;
  align-items: center;
  padding: 0 100px;
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.primary};
  z-index: 100;

  @media (max-width: 800px) {
    padding: 0 10px;
    &.active {
      > div:before {
        top: 14px;
        transform: rotate(45deg);
        box-shadow: 0 0 0 #fff;
      }
      > div:after {
        bottom: 14px;
        transform: rotate(-45deg);
      }
    }
  }
  @media (max-width: 300px) {
    &.active {
      > div:before {
        top: 7px;
      }
      > div:after {
        bottom: 6px;
      }
    }
  }
`

const Header = () => {
  const [activeMenu, setActiveMenu] = useState(false)
  const toggle = () => {
    setActiveMenu(x => !x)
  }
  return (
    <HeaderContainer className={activeMenu ? "active" : ""} id="header">
      <LogoText src={logoText} alt="Firma z drewna" to="home" />
      <Menu callback={() => toggle()} activeMenu={activeMenu} />
      <Toggle callback={() => toggle()} />
    </HeaderContainer>
  )
}

export default Header
