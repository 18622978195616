import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const StyledLi = styled.li`
  position: relative;
  list-style: none;
  margin: 5px 0;
`

const StyledLink = styled(Link)`
  position: relative;
  width: 100%;
  padding: 5px 10px;
  font-weight: 800;
  font-size: 1.3em;
  color: black;
  display: inline-block;
  :hover,
  &.active {
    text-decoration: none;
    color: ${({ theme }) => theme.green};
  }

  @media (max-width: 800px) {
    font-size: 1.5em;
    opacity: 0;
    padding: 5px 50px;
    font-weight: 700;
    margin: 10px 0;
  }
  @media (max-width: 260px) {
    font-size: 1em;
  }
`

const MenuElement = ({ scrollTo, title, callback }) => {
  return (
    <StyledLi className="navigation">
      <StyledLink
        to={`#${scrollTo}`}
        onClick={e => {
          callback()
          e.preventDefault()
          document.getElementById(scrollTo).scrollIntoView()
        }}
      >
        {title}
      </StyledLink>
    </StyledLi>
  )
}

export default MenuElement
