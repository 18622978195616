import React from "react"
import styled, { ThemeProvider } from "styled-components"
import Contact from "../components/organisms/Contact"
import Seo from "../components/seo"
import Header from "../components/organisms/Header"
import { theme } from "../theme/mainTheme"

const ContactSection = styled.section`
  background: ${({ theme }) => theme.primary};
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;

  @media (max-width: 100px) {
    padding: 20px 0 0 0;
  }
`

const MainTemplate = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Seo
        description="Firma z drewna- Precyzyjne wycinanie laserowe nawet najbardziej skomplikowanych projektów."
        title="Firma z drewna"
      ></Seo>
      <Header />
      {children}
      <ContactSection id="contact">
        <Contact />
      </ContactSection>
    </ThemeProvider>
  )
}

export default MainTemplate
