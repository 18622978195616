import React from "react"
import styled from "styled-components"
import ModalImage from "react-modal-image"

const StyledModalImage = styled(ModalImage)`
  min-width: 450px;
  @media (max-width: 600px) {
    min-width: 0;
  }
`

const ProductIcon = ({ photo, alt }) => {
  return (
    <StyledModalImage
      alt={alt}
      hideDownload="true"
      hideZoom="true"
      small={photo.small.src}
      large={photo.large.src}
    />
  )
}

export default ProductIcon
