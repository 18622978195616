import React from "react"
import styled from "styled-components"
import ShowMoreText from "react-show-more-text"
import { ResizeGridItems } from "../../helpers/functions"

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
`

const StyledTitle = styled.div`
  cursor: default;
  color: black;
  font-weight: 600;
  text-align: center;
  overflow: hidden;
  font-size: 2em;
  @media (max-width: 400px) {
    font-size: 8vw;
  }
`

const StyledShowMoreText = styled(ShowMoreText)`
  cursor: default;
  color: black;
  font-family: "Lato";
  font-weight: 300;
  height: fit-content;
  text-align: center;
  width: 100%;
  margin-top: 10px;
  text-justify: inter-word;
  font-size: 1.2em;
  @media (max-width: 400px) {
    font-size: 4vw;
  }
  a {
    font-weight: 400;
    color: ${({ theme }) => theme.green};
    text-decoration: none;
    min-width: 100px;
    :hover {
      color: rgb(98, 153, 14);
    }
  }
`

const ProductDescription = ({ title, description }) => {
  return (
    <StyledWrapper>
      <StyledTitle>{title}</StyledTitle>
      <StyledShowMoreText
        lines={3}
        more="Rozwiń"
        less="Ukryj"
        onClick={ResizeGridItems}
      >
        {description}
      </StyledShowMoreText>
    </StyledWrapper>
  )
}

export default ProductDescription
