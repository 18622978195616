import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const StyledLink = styled(Link)`
  z-index: 10;
  @media (max-width: 800px) {
    margin: auto;
    padding-left: 30px;
  }
  @media (max-width: 300px) {
    padding-left: 15px;
  }
`

const StyledImg = styled.img`
  margin: auto 0;
  cursor: pointer;
  @media (max-width: 400px) {
    width: 50vw;
  }
`

const LogoText = ({ src, alt, to }) => {
  return (
    <StyledLink
      to={`#${to}`}
      onClick={e => {
        e.preventDefault()
        document.getElementById(to).scrollIntoView()
      }}
    >
      <StyledImg src={src} alt={alt} />
    </StyledLink>
  )
}

export default LogoText
