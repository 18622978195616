import React from "react"
import styled from "styled-components"
import instagram from "../../assets/instagram.svg"
import { Facebook2 } from "@styled-icons/icomoon/Facebook2"

const MediaContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (min-width: 1002px) {
    margin-top: auto;
    margin-bottom: auto;
    flex-direction: column;
    padding-top: 37px;
  }

  @media (max-width: 1002px) {
    margin: auto;
  }
`

const StyledFacebook = styled(Facebook2)`
  margin: 10px;
  width: 35px;
  height: 35px;
  @media (max-width: 1000px) {
    margin: 10px 15px;
  }
  @media (max-width: 200px) {
    width: 15px;
    height: 15px;
    margin: 10px 3px;
  }
  color: #0e8ef1;
  cursor: pointer;
`

const StyledInstagram = styled.img`
  margin: 10px;
  width: 35px;
  height: 35px;
  @media (max-width: 1000px) {
    margin: 10px 15px;
  }
  @media (max-width: 200px) {
    width: 15px;
    height: 15px;
    margin: 10px 3px;
  }
  cursor: pointer;
`

const MediaIcons = () => {
  return (
    <MediaContainer>
      <a
        href="https://www.facebook.com/Firma-z-Drewna-117128053531341"
        rel="noreferrer"
        target="_blank"
      >
        <StyledFacebook alt="Facebook" />
      </a>
      <a
        href="https://www.instagram.com/firmazdrewna/"
        rel="noreferrer"
        target="_blank"
      >
        <StyledInstagram alt="Instagram" src={instagram} />
      </a>
    </MediaContainer>
  )
}

export default MediaIcons
