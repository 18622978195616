import React from "react"
import styled from "styled-components"
import map from "../../assets/newMap.png"

const StyledMapLinkContainer = styled.a`
  margin-left: auto;
  @media (max-width: 1000px) {
    margin-right: auto;
    padding-top: 20px;
  }
  :hover {
    color: ${({ theme }) => theme.primary};
  }
`

const StyledMapPhoto = styled.img`
  margin-bottom: 10px;
  width: 500px;
  border: 1px solid rgb(125, 128, 121);
  @media (max-width: 600px) {
    width: 100%;
  }
  @media (min-width: 1000px) {
    min-height: 225px;
  }
  @media (max-width: 1000px) {
    margin: auto;
  }
`

const StyledText = styled.div`
  font-weight: 600;
  font-size: 1.5em;
  cursor: pointer;
  margin-bottom: 10px;
  color: black;
  ${StyledMapLinkContainer}:hover & {
    color: ${({ theme }) => theme.green};
  }

  @media (max-width: 400px) {
    font-size: 6vw;
  }
`

const Map = () => {
  return (
    <StyledMapLinkContainer
      href="https://www.google.com/maps/place/Powsta%C5%84c%C3%B3w+Wielkopolskich+3%2F8,+86-200+Che%C5%82mno/@53.3488074,18.4296693,18z/data=!3m1!4b1!4m5!3m4!1s0x4703209e8052c975:0x3d207f3a50c3d903!8m2!3d53.3488058!4d18.4309568?entry=ttu"
      target="_blank"
      rel="noreferrer"
    >
      <StyledText>Znajdź nas w Google Maps</StyledText>
      <StyledMapPhoto alt="Map" src={map} />
    </StyledMapLinkContainer>
  )
}

export default Map
